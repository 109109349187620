import { schemas } from "@practice/sdk";
import { z } from "zod";

import {
  OnTrackNotificationsType,
  PackageBillingRateTypeSchema,
  PackageContentTypeSchema,
  PackageFrequencySchema,
  PackageOnTrackRulesType,
  PackagePaymentOptionSchema,
  PackageTimeTypeSchema,
  PackageTypeSchema,
  UsagePackageInvoicingSchema,
  UsagePackagePricingSchema,
} from "@lib/data/schemas/packages";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

const PackagePaymentStatusEnum = z.enum([
  "paid",
  "failed",
  "scheduled",
  "shared",
  "cancelled",
]);
export type PackagePaymentStatusEnumType = z.infer<
  typeof PackagePaymentStatusEnum
>;

const PackageInstanceStatusEnum = z.enum([
  "active",
  "deleted",
  "completed",
  "blocked",
  "scheduled",
  "paused",
]);

export type PackageInstanceStatusType = z.infer<
  typeof PackageInstanceStatusEnum
>;

export const PackageInvoiceOptionEnum = z.enum([
  "none",
  "draft",
  "shared-via-email",
  "scheduled",
]);
export type PackageInvoiceOptionEnumType = z.infer<
  typeof PackageInvoiceOptionEnum
>;

export const PackageInstancePaymentSchema = z.object({
  invoiceId: z.string(),
  dueDate: z.date(),
  paidAt: z.date().optional(),
  title: z.string(),
  status: PackagePaymentStatusEnum,
  currency: z.string(),
  amount: z.number(),
  paymentIntentId: z.string().optional(),
});

export type PackageInstancePaymentType = z.infer<
  typeof PackageInstancePaymentSchema
>;

export const PackageInstanceItemsSchema = z.array(
  z.object({
    productId: z.string().optional(),
    schedulerId: z.string(),
    quantity: z.number(),
    usagePricing: UsagePackagePricingSchema.optional(),
    suggestedFrequency: PackageFrequencySchema.optional(),
    billingRate: schemas.BillingRate.optional(),
    memberIds: z.array(z.string()).optional(),
    curriculum: z.boolean().optional(),
  })
);

const CyclePausesSchema = z.record(
  z.string(),
  z.array(
    z.object({
      startDate: z.date(),
      endDate: z.date().optional(),
      cycleEndDate: z.date().optional(),
    })
  )
);

export const PackageTrackingStatusTypes = z.enum([
  "onTrack",
  "atRisk",
  "offTrack",
  "disabled",
  "paused",
  "completed",
]);

export const OnTrackHistoryType = z.object({
  status: PackageTrackingStatusTypes,
  date: z.date(),
  action: z.enum(["updatedStatus", "sentNotification"]),
  smartActionId: z.string().optional(),
});

export type CyclePausesType = z.infer<typeof CyclePausesSchema>;

export const PackageInstanceSchema = BaseSchema.extend({
  title: z.string(),
  icon: z.string(),
  items: PackageInstanceItemsSchema,
  payments: z.array(PackageInstancePaymentSchema),
  packageId: z.string(),
  status: PackageInstanceStatusEnum,
  clientId: z.string(),
  isAssignedByCoach: z.boolean().optional(),
  invoiceOption: PackageInvoiceOptionEnum.optional(),
  scheduledSmartActions: z.array(z.string()).optional(),
  coachUserId: z.string().optional(),
  distributeSessions: z.boolean().optional(),
  totalSessions: z.number().nullable().optional(),
  contentType: PackageContentTypeSchema.optional(),
  timeType: PackageTimeTypeSchema.nullable().optional(),
  autocomplete: z.boolean().optional(),
  packageType: PackageTypeSchema.optional(),
  frequency: PackageFrequencySchema.nullable().optional(),
  paymentOption: PackagePaymentOptionSchema.optional(),
  customSubscriptionId: z.string().nullable().optional(),
  startDate: z.date().optional(),
  resetDate: z.date().nullable().optional(),
  rollOver: z.boolean().optional(),
  currentCycle: z.number().optional(),
  // @deprecated: This prop was the old way of storing payment related.
  //              Use the `payments` prop instead.
  invoiceId: z.string().optional(),
  billingRate: schemas.BillingRate.optional(),
  packageBillingRateType: PackageBillingRateTypeSchema.optional().nullable(),
  acceptedOutcomes: z.array(z.string()).nullable().optional(),
  autoCancelOutcome: z.boolean().optional().nullable(),
  cycleExtensions: z.record(z.string(), z.number()).optional(),
  cyclePauses: CyclePausesSchema.optional(),
  usageInvoicing: UsagePackageInvoicingSchema.optional().nullable(),
  setupIntentId: z.string().optional(),
  endDate: z.date().optional(),
  locked: z.boolean().optional(),
  supervisors: z.array(z.string()).optional(),
  organizationSettings: z
    .object({
      hideNotesAndFormsFromManagers: z.boolean().optional(),
    })
    .optional(),
  participants: z.array(z.string()).optional(),
  organizationId: z.string(),
  depositRule: z
    .enum(["start-of-engagement", "end-of-engagement", "no-deposit"])
    .optional(),
  onTrackRules: PackageOnTrackRulesType.nullable().optional(),
  onTrackStatus: PackageTrackingStatusTypes.optional(),
  onTrackHistory: z.array(OnTrackHistoryType).optional(),
  onTrackNotifications: OnTrackNotificationsType.optional().nullable(),
  onTrackEnabled: z.boolean().optional(),
});

export type PackageInstanceType = z.infer<typeof PackageInstanceSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "packageInstances",
  deleteMode: "soft",
};
